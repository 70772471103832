.about{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about .title{
    font-size: 2em;
    color: #333;

    text-align: left;
   
}
hr{
    width: 20%;
}


.about .description{
    font-size: 1.3em;
    color: #333;
    
    text-align: left;
    width: 100%;
    max-width: 800px;    
}

.text{
    font-size: 1em;
}
@media screen and (max-width: 768px) {
    h1{
        font-size: 1em;
    }
    .text{
        font-size: 0.7em;
    }
    .about .title{
        font-size: 1.5em;
    }
    .about .description{
        font-size: 1em;
    }
}