@font-face {
  font-family: "Yeezy";
  src: url("/public/yeezy_tstar-bold-webfont.ttf");
}
@font-face {
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUXskPMA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.App {
  width: 100%;
  font-size: 20px;
}
.App-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Yeezy", sans-serif;
  height: 70px;
  align-items: center;
}
.App-logo {
  max-width: 150px;
  margin: 0;
  padding: 0;
}
.App-logo img {
  width: 100%;
  object-fit: cover;
}
.navlinks {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.App-header p {
  font-size: 1.2em;
  color: black;
  margin: 0;
  padding: 0;
  text-align: center;

  padding: 10px;
  margin: 0;
  font-family: "Yeezy", sans-serif;
  transition: 250ms ease-in-out;
}
.App-header a {
  text-decoration: none;
}
.App-header p:hover {
  color: #43db46;
}
#call-btn,
#menu-btn {
  background-color: #43db46;
  color: white;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 1.2em;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  filter: drop-shadow(0px 0px 5px #43db46(0, 0, 0, 0.5));
  border: none;
  cursor: pointer;
  transition: 250ms ease-in-out;
}
#menu-btn {
  display: none;
}
#call-btn:hover {
  filter: drop-shadow(0px 0px 5px #43db46);
  color: #43db46;
  background-color: white;
}

main {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  min-height: 100dvh;
}
.highlight-video {
  width: 100%;
}
.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  position: relative;
}
.video-container video {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.video-container h1 {
  font-size: 2em;
  font-family: "Space Grotesk";
  color: white;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 20%;
  z-index: 1;
}
.brand-list {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.brand-list .scrollable.list {
  width: 100%;
  list-style: none;

  height: 220px;
  padding: 0 0;
  margin: 0;
  display: flex;
  overflow: hidden;
}
.scrollable.list .item {
  width: 15%;
  padding: 0;
  margin: 0;

  height: 95%;
  min-width: 195px;
}

.scrollable.list a {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  color: black;

  transition: color 250ms ease-in-out;
}
.scrollable.list a:hover {
  background-color: #43db46;
  color: transparent;
  box-shadow: 0px 0px 5px #43db46;
  border: #43db46 1px solid;
  background-clip: text;
  border-radius: 20px;
}
.scrollable.list .item img {
  width: 90%;
  object-fit: cover;
  padding: 40px;
  box-sizing: border-box;
}
.brand-list button {
  width: 50px;
  height: 50px;
  position: absolute;
  transform: translateY(50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
#prevBtn {
  left: 0;
}
#nextBtn {
  right: 0;
}
.latest-product-list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.product-list {
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 90%;

  font-family: "Space Grotesk";
  justify-content: center;
}
.product-list .item {
  width: 300px;

  border-radius: 5px;
  box-shadow: 0px 0px 1px black;
}
.product-list .item:hover {
  box-shadow: 0px 0px 3px black;
}
.product-list .item .card {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
.product-list .item img {
  width: 100%;
  object-fit: cover;
}
.card-body {
  padding: 0;
}
.card-body a {
  text-decoration: none;
  color: black;
}
.card-body h3 {
  text-decoration: none;
  font-family: "Yeezy";

  color: black;
}
.card-body button {
  color: #43db46;
  font-size: 0.9em;
}
.card-body a:hover {
  text-decoration: underline;
}

footer {
  width: 100%;
  
  background-color: rgb(0, 0, 0);
  color: white;
  font-family: 'Space Grotesk';
  position: static;
  bottom: 0;
}
footer div {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
footer ul {
  text-align: center;
  margin: 0;
  padding: 0;
}
footer .social-links {
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: row;
}
footer .social-links li {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
}
.side-menu {
  width: 90dvw;
  height: 100dvh;
  background-color: #050b20;
  position: fixed;
  left: 0;
  top: 0;
  color: white;
  display: none;
  transition: 150ms ease-in-out;
  transform: translateX(-100%);
}
.side-menu.active {
  transform: translateX(0);
  z-index: 2;
}
.side-menu ul {
  list-style: none;
}
.side-menu ul li a {
  text-decoration: none;
  color: currentColor;
  font-family: "Space Grotesk";
}
#close-menu-btn {
  background-color: transparent;
  color: white;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
}
.sticky-tab-links {
  display: none;
}
.App-products {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.App-products.product-list {
}
@media screen and (max-width: 768px) {
  .brand-list .scrollable.list {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  #prevBtn,
  #nextBtn {
    display: none;
  }
  .scrollable.list .item {
    scroll-snap-align: center;
    width: 15%;
    padding: 0;
    margin: auto;
    height: 50%;
    min-width: 155px;
  }
  .scrollable.list .item img {
    width: 80px;
    height: 50%;
    object-fit: cover;
    padding: 0;
    box-sizing: border-box;
  }
  .product-list .item {
    width: 160px;
    height: 270px;
  }
  .card-body button {
    color: #ffffff;
    font-size: 0.7em;
  }
  .card-body h3 {
    font-size: 1em;
    text-decoration: none;
  }
  .card-text {
    font-size: 0.7em;
  }
  .side-menu {
    display: block;
  }
  #call-btn {
    display: none;
  }
  .navlinks {
    display: none;
  }
  #menu-btn {
    display: block;
  }
  .sticky-tab-links {
    display: block;
    background-color: grey;
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    box-shadow: 0px 0px 11px -2px black;
  }
  .title{
    text-align: center;
  }

  .sticky-tab-links ul {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    gap: 5px;
    padding: 0;
    margin: 0;
    height: 60px;
    align-items: center;
    padding: 0 5px;
  }
  .sticky-tab-links li {
    width: 20%;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .sticky-tab-links a {
    color: white;
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sticky-tab-links img {
    width: 30px;
    height: 30px;
    color: white;
  }
  .sticky-tab-links p {
    font-size: 0.6em;
    margin: 0;
  }
  .title {
    font-size: 1.5em;
    text-align: center;
  }
  footer{
    display: none;
  }
}
