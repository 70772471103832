.preview.image {
  max-width: 440px;

  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
}
.preview.image img {
  width: 100%;
  scroll-snap-align: center;
}
.num-of-img-text {
  position: absolute;
  bottom: 0;
  right: 0;
}
.preview-image-wrapper {
  position: relative;
}
.product-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.send-inquiry-btn {
  padding: 10px 20px;
  border: none;
  color: black;
  border-radius: 15px;
  font-family: Yeezy;
  transition: 150ms ease-in-out;
}
.send-inquiry-btn:hover {
  background-color: #43db46;
  color: white;
}
.description{
    font-size: 1.1em;
    color: #333;
    
    text-align: left;
    width: 100%;
    max-width: 600px;  
}